import {useState} from 'react'
import './forms.css'
import {auth} from './firebase'
import {useHistory, Link} from 'react-router-dom'
import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import {useAuthValue} from './AuthContext'
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import ReactPlayer from 'react-player/vimeo'

const MEMBERSHIP_CODE = '24ad0c14-afcf-11ec-b909-0242ac120002'

function Register() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [membershipCode, setMembershipCode] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const history = useHistory()
  const {setTimeActive} = useAuthValue()

  const validatePassword = () => {
    let isValid = true
    if (password !== '' && confirmPassword !== ''){
      if (password !== confirmPassword) {
        isValid = false
        setError('Passwords does not match')
      }
    }
    return isValid
  }

  const validateMembershipCode = () => {
    if (membershipCode === MEMBERSHIP_CODE) {
      return true
    } else {
      setError('Invalid membership code')
      return false
    }
  }

  const register = e => {
    e.preventDefault()
    setError('')
    if(validatePassword() && validateMembershipCode()) {
      // Create a new user with email and password using firebase
        createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          sendEmailVerification(auth.currentUser)   
          .then(() => {
            setTimeActive(true)
            history.push('/')
          }).catch((err) => alert(err.message))
        })
        .catch(err => setError(err.message))
    }
    setEmail('')
    setPassword('')
    setConfirmPassword('')
  }

  return (
    <div className='center'>
      <div className="auth-register">
        <ReactPlayer url="https://vimeo.com/692268047" controls style={{margin: 'auto'}} />
        <div className='center'>
          <div className='auth'>
            <h1>Register</h1>
            {error && <Alert severity="error" className="auth__error">{error}</Alert>}
            <form onSubmit={register} name='registration_form'>
            <input
                value={membershipCode}
                placeholder="Enter your membership code"
                required
                onChange={e => setMembershipCode(e.target.value)}/>

              <input 
                type='email' 
                value={email}
                placeholder="Enter your email"
                required
                onChange={e => setEmail(e.target.value)}/>

              <input 
                type='password'
                value={password} 
                required
                placeholder='Enter your password'
                onChange={e => setPassword(e.target.value)}/>

                <input 
                type='password'
                value={confirmPassword} 
                required
                placeholder='Confirm password'
                onChange={e => setConfirmPassword(e.target.value)}/>

              <Button type='submit' variant="contained">Register</Button>
            </form>
            <span>
              Already have an account?
              &nbsp;
              <Link to='/login'>Login</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
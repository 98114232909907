import { Link } from 'react-router-dom'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useContentValue } from './ContentContext';


function CategoryList() {
  const { contentData } = useContentValue()

  if (!contentData) {
    return null
  }

  const { categories } = contentData;

  const serializeCategory = (name) => {
    return name.toLowerCase().replace(' ', '-');
  }

  return (
      <div className='center'>
        <div className='profile'>
          <h1>Categories</h1>
          <List className="link-list capitalize">
            {categories.map((category) => {
              return (
                <>
                  <Link
                    to={`/categories/${serializeCategory(category.name)}`}
                    key={category.name}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <img src={`/${category.icon}.svg`} alt={`${category.icon} icon`} />
                      </ListItemIcon>
                      <ListItemText primary={category.name.toLowerCase()} />
                    </ListItemButton>
                  </Link>
                </>
              )
            })}
          </List>
        </div>
      </div>
  )
}

export default CategoryList

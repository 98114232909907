import React, { useContext, useState } from 'react'
import { useEffect } from 'react'

const ContentContext = React.createContext()
const CONTENT_DATA_URL = 'https://learningmojo.s3.us-east-2.amazonaws.com/Model.json'

export function ContentProvider({children, value}) {
  const [contentData, setContentData] = useState({})

  useEffect(() => {
    fetch(CONTENT_DATA_URL).then((res) => {
    res.json().then((data) => {
      setContentData(data)
    })
    })
  }, [])

  return (
    <ContentContext.Provider value={{contentData}}>
      {children}
    </ContentContext.Provider>
  )
}

export function useContentValue(){
  return useContext(ContentContext)
}

import { Link } from 'react-router-dom'
import { IconButton, Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { useState } from 'react';

function Header(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const goBack = () => {
    window.history.back()
  }

  return (
    <div className="app-header">
      <div className="nav">
        {props.currentUser && <Button onClick={goBack}>Back</Button>}
        <Link to="/" className="naked-link">
          <img className="mojo-logo" src='/mojo-logo.png' alt='mojo logo' />
        </Link>
        {props.currentUser && 
          <>
            <IconButton
              id="basic-button"
              color="primary"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={openMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Link className="naked-link" to="/">
                <MenuItem onClick={handleClose}>
                Home
                </MenuItem>
              </Link>
              <Link className="naked-link" to="/community">
                <MenuItem onClick={handleClose}>
                  Community
                </MenuItem>
              </Link>
              <Link className="naked-link" to="/profile">
                <MenuItem onClick={handleClose}>
                    Profile
                </MenuItem>
              </Link>
            </Menu>
          
        </>
        }
      </div>
    </div>
  );
}

export default Header;

import './App.css';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Footer from './Footer'
import AudioList from './AudioList'
import CategoryList from './CategoryList'
import Profile from './Profile'
import Register from './Register'
import VerifyEmail from './VerifyEmail';
import Login from './Login'
import {useState, useEffect} from 'react'
import {AuthProvider} from './AuthContext'
import {ContentProvider} from './ContentContext'
import {auth} from './firebase'
import {onAuthStateChanged} from 'firebase/auth'
import PrivateRoute from './PrivateRoute'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Community from './Community';
import Header from './Header';

function App() {

  const [currentUser, setCurrentUser] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const [timeActive, setTimeActive] = useState(false)
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  })

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setLoadingUser(false)
    })
  }, [])

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider value={{currentUser, loadingUser, timeActive, setTimeActive}}>
          <ContentProvider>
            <Header currentUser={currentUser} />
            <Switch>
              <PrivateRoute exact path="/" component={CategoryList} />
              <PrivateRoute exact path="/categories/:name" component={AudioList} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/community" component={Community} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path='/verify-email' component={VerifyEmail} /> 
            </Switch>
            <Footer />
          </ContentProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;

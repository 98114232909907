import { AmityUiKitProvider, AmityUiKitSocial } from '@amityco/ui-kit-open-source'
import { useAuthValue } from './AuthContext'

function Community() {
  const {currentUser} = useAuthValue()

  if (!currentUser?.email) {
    return null;
  }

  return (
    <div className='community'>
      <AmityUiKitProvider
        key={currentUser?.email}
        userId={currentUser?.email}
        displayName={currentUser?.email.split('@')[0]}
        apiKey={process.env.REACT_APP_AMITY_KEY}
        apiRegion="us"
      >
        <AmityUiKitSocial />
      </AmityUiKitProvider>
    </div>
  )
}

export default Community

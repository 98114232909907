import {useState} from 'react'
import {Link} from 'react-router-dom'
import './forms.css'
import {signInWithEmailAndPassword, sendEmailVerification, getAuth, sendPasswordResetEmail} from 'firebase/auth'
import {auth} from './firebase'
import {useHistory} from 'react-router-dom'
import {useAuthValue} from './AuthContext'
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

function Login(){

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('') 
  const [resetEmailSent, setResetEmailSent] = useState(false)
  const [error, setError] = useState('')
  const {setTimeActive} = useAuthValue()
  const history = useHistory()

  const login = e => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      if(!auth.currentUser.emailVerified) {
        sendEmailVerification(auth.currentUser)
        .then(() => {
          setTimeActive(true)
          history.push('/verify-email')
        })
      .catch(err => alert(err.message))
    }else{
      history.push('/')
    }
    })
    .catch(err => setError(err.message))
  }

  const forgotPassword = () => {
    const auth = getAuth();
    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setError('')
          setResetEmailSent(true)
        })
        .catch((error) => {
          setError('Please enter a valid email, or try again later.')
        });
    } else {
      setError('Please enter an address for the reset email')
    }
  }

  return(
    <div className='center'>
      <div className='auth'>
        <h1>Log in</h1>
        {error && <Alert severity="error" className="auth__error">{error}</Alert>}
        <form onSubmit={login} name='login_form'>
          <input 
            type='email' 
            value={email}
            required
            placeholder="Enter your email address"
            onChange={e => setEmail(e.target.value)}/>

          <input 
            type='password'
            value={password}
            required
            placeholder='Enter your password'
            onChange={e => setPassword(e.target.value)}/>

          <Button type='submit' variant="contained">Login</Button>
        </form>
        <p>
          Have a membership code?
          &nbsp;
          <Link to='/register'>Create your account here</Link>
        </p>
        <p>
          Forgot password?
          &nbsp;
          {resetEmailSent
            ? <strong>Reset email sent!</strong>
            : <a href='/login#' onClick={forgotPassword}>Send reset email</a>
          }
        </p>
      </div>
    </div>
  )
}

export default Login
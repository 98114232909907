import {Route, Redirect} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

export default function PrivateRoute({component:Component, ...rest}) {
  const { currentUser, loadingUser } = useAuthValue()

  return loadingUser ? null : (
    <Route
      {...rest}
      render={props => {
        return currentUser ? <Component {...props} /> : <Redirect to='/login' />
    }}>
    </Route>
  )
}
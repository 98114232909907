import {useState} from 'react'


function Footer() {
  const [showAppStore, setShowAppStore] = useState(false)

  return (
    <div className="app-footer">
      <p>
        <a href="https://www.joinmojo.com/downloads" target="_blank" rel="noreferrer" className="naked-link">👉 Get the Mojo Planner 📓</a>
      </p>
      <p className="fake-link" onClick={() => setShowAppStore(!showAppStore)}>
        {showAppStore ? '👇' : '👉' } Get the Mojo iOS App 📲
      </p>
      {showAppStore && 
        <>
          <a href="https://apps.apple.com/us/app/mojo/id1518324274">
            <img src='/download-app.png' alt='download on the app store' />
          </a>
          <br />
          <img className="download-app-qr" src='/app-store-qr.png' alt='QR code to download on the app store' />
        </>
      }
    </div>
  )
}

export default Footer

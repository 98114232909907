import { useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeadsetOutlinedIcon from '@mui/icons-material/HeadsetOutlined';
import { useContentValue } from './ContentContext';

const S3_BASE_URL = 'https://learningmojo.s3.us-east-2.amazonaws.com'

function AudioList(props) {
  const { name } = useParams();
  const { contentData } = useContentValue()

  if (!contentData) {
    return null
  }

  const { questions } = contentData;
  const categoryName = name.toUpperCase().replace('-', ' ');

  const categoryQuestions = questions.filter(question => question.category.toLowerCase() === categoryName.toLowerCase());

  /** Prevents user from downloading audio files through context menu */
  const disableRightClick = (e) => {
    e.preventDefault()
  }

  return (
      <div className='center'>
        <div className='profile audio-list'>
          <h1 className="capitalize">{categoryName.toLowerCase()}</h1>
            {categoryQuestions.map((question) => {
              return (
                <Accordion key={question.question}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <span className="accordion-summary-title">
                      <HeadsetOutlinedIcon />
                      {question.question}
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <audio controls onContextMenu={disableRightClick} src={`${S3_BASE_URL}/${question.fileName}`}></audio>
                  </AccordionDetails>
                </Accordion>
              )
            })}
        </div>
      </div>
  )
}

export default AudioList;
